import type { JSX } from 'react';
import { ImageGallery } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type ImageGalleryProps = SliceComponentProps<Content.ImageGallerySlice>;

const ImageGallerySlice = ({ slice }: ImageGalleryProps): JSX.Element => (
  <ImageGallery {...slice} />
);

export default ImageGallerySlice;
